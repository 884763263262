import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import CateringIconImage from "images/catering.jpg";
import StaffIconImage from "images/staff.jpeg";
import GeneralIconImage from "images/general.jpg";
import FastIconImage from "images/physical.jpg";
import ReliableIconImage from "images/relatives.jpg";
import SimpleIconImage from "images/safe.jpg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-4 md:py-4`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`text-center flex-shrink-0 relative`}
    img {
      ${tw`w-full rounded-md`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose text-center`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  
  const cards = [
    {
      imageSrc: StaffIconImage,
      title: "Health Professionals",
      description:
        "We offer skilled care to enable people who live here to achieve their optimum state of health and well-being. Our team is a dedicated team of nurses’, healthcare assistants, an activities coordinator and a handyman.",
    },
    {
      imageSrc: CateringIconImage,
      title: "Catering",
      description:
        "Our food is guaranteed to satisfy your appetite. There is a choice of home cooked meals and specialized dietary needs are catered for. Our clients will have the choice to self cater.",
    },
    {
      imageSrc: GeneralIconImage,
      title: "General Services",
      description:
        "Daily living within our homes is designed to offer you a wide range of interests; we aim to ensure that there is always something interesting and stimulating going on.",
    },
    {
      imageSrc: ReliableIconImage,
      title: "Relatives and Visitor",
      description:
        "Visits are special times for our residents, and we encourage family and friends to keep in regular contact and visit as often as they want.",
    },
    {
      imageSrc: FastIconImage,
      title: "Physical Environment",
      description:
        "The residential accommodation provides three good size bedrooms with en-suite facilities and each room is equipped with a TV (upon request). All bedrooms are equipped with smoking detectors.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "Safe Environment",
      description:
        "The houses which are part of the local communities provide a safe and supportive environment, which allows everyone the opportunity to develop skills for more independent living in the future.",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Supported Living <span tw="text-primary-500">Services</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
