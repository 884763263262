import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-16 pb-10 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const SingleRow = tw.div`max-w-screen-xl mx-auto pb-24`;
const SingleContent = tw.p`py-4`;

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default ({
  subheading,
  heading,
  description,
  address,
  imageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true
}) => {

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{address}</Description>
            <Description>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
      <SingleRow>
        <SingleContent>We offer flexible support options that can be tailored to meet your individual needs. Our support can range from few hours per week to 24/7 support in your own home (including waking night or sleep in staff) with a senior member of staff contactable 24 hours a day. Our services in the Hayes area provide good links to the local community and is within 5 minutes’ walk of the Uxbridge Road.</SingleContent>
        <SingleContent>At Hanover Supported Living, we promote healthy active lifestyles and with great emphasis on managing personal finances, budgeting and the development of creative cooking skills. Progresses and achievements activities will naturally increase confidence and self-esteem. We help clients to expand their social networks and access opportunities available in their local area such as education, work, whether paid or voluntary, exercise and sports facilities and other hobbies that will be of interest and beneficial to their well-being.</SingleContent>
        <SingleContent>We can support you in all aspects of your life. We will encourage you to do as much as you can for yourself so that you build and develop your independence and have as much control over your life as possible. Our staff will help you to develop practical skills you need to live independently as well as being there for advice, guidance and emotional support when you need it.</SingleContent>
        <SingleContent>Our staff members each have a wealth of knowledge, skills and experience in supporting and enabling people of all abilities to develop and lead a life as independent as can be.</SingleContent>
      </SingleRow>
    </Container>
  );
};
