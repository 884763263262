import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXlN } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Heading = tw(SectionHeading)`w-full`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200`;
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const AnswerTitle = tw.p`text-center pt-1 pb-3 font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "The Physical Environment",
  faqs = [
    {
      question: "Accomodation",
      answer:
        "The residential accommodation provides three good size bedrooms with en-suite facilities and each room is equipped with a TV (upon request). All bedrooms are equipped with smoking detectors.",
    },
    {
      question: "TV Lounge",
      answer:
        "A TV lounge area which provides video, DVD, Satellite TV Services and is equipped with an entertainment game console.",
    },
    {
      question: "Communal Dining",
      answer:
        "The open plan area comprises of the lounge and communal dining area where the menu choice for several days in advance is displayed. Adjoined is a well equipped kitchen.",
    },
    {
      question: "Garden",
      answer:
        "Outside is a patio which leads to the garden area where residents can enjoy sitting outside for fresh air or do a bit gardening and the garden also has a designated smoking area.",
    },
    {
      question: "Transportation",
      answer:
        "The care home has a seven seater people’s carrier for travelling purposes when required.",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXlN>
        <Column>
          <HeaderContent>
            <Heading>{heading}</Heading>
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ className="group" key={index}>
                <Answer>
                  <AnswerTitle>{faq.question}</AnswerTitle>
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXlN>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
