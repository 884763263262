import axios from "axios";

// config
import { config } from "./config";

const axiosInstance = axios.create({
  baseURL: config.api.baseURL,
  timeout: 5000,
});

// request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
