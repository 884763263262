import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXlN } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200`;
const Answer = motion(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

const AnswerTitle = tw.p`text-center pt-1 pb-3 font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "Our Care",
  heading = "What makes us special?",
  description = "New Horizon is a privately owned residential care home. It is a three bedded unit for people suffering from various identified mental health problems. Our goal is to promote our clients Recovery through the process of providing Hope, Control and Opportunity.",
  faqs = [
    {
      question: "Our Staff",
      answer:
        "We offer skilled care to enable people who live here to achieve their optimum state of health and well-being. Our team is a dedicated team of nurses’, healthcare assistants, an activities coordinator and a handyman. This ensures that residents’ requirements are met 24 hours a day. A Registered Nurse will be accessible at all times.",
    },
    {
      question: "Catering",
      answer:
        "Our food is guaranteed to satisfy your appetite. There is a choice of home cooked meals and specialized dietary needs are catered for. Our clients will have the choice to self cater.",
    },
    {
      question: "General Services",
      answer:
        "Daily living within our homes is designed to offer you a wide range of interests; we aim to ensure that there is always something interesting and stimulating going on. Our activity coordinator will plan and facilitate a variety of daily activities with you in mind including psycho educational groups. A structured time table will reflect all sessions and activities provided at New Horizon.",
    },
    {
      question: "Relatives and Visitors",
      answer:
        "Visits are special times for our residents, and we encourage family and friends to keep in regular contact and visit as often as they want. We also welcome your involvement in your loved one’s care – keeping you up to date on their health and wellbeing. A visitors’ book is available for your feedback without prejudice.",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXlN>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ className="group" key={index}>
                <Answer key={index}>
                  <AnswerTitle>{faq.question}</AnswerTitle>
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXlN>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
