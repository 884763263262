import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo512.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

import { faPhoneVolume, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = tw(ContainerBase)`bg-secondary-500 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const ContactContainer = tw.div`flex items-center justify-center md:justify-start mb-10`;
const ContactText = tw.p`ml-2 text-center font-medium tracking-wider text-primary-200 leading-10`;
const ContactLink = tw.a`border-b-2 border-transparent text-gray-300 hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-1`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <ContactContainer>
            <ContactText>
              For more information on the care and specialist support services
              that we can offer, please contact us today on{" "}
              <ContactLink href="tel:02036020450">
                <FontAwesomeIcon icon={faPhoneVolume} /> 0203 602 0450
              </ContactLink>{" "}
              or email us at{" "}
              <ContactLink href="mailto:info@newhorizoncare.co.uk">
                <FontAwesomeIcon icon={faEnvelope} /> info@newhorizoncare.co.uk
              </ContactLink>
            </ContactText>
          </ContactContainer>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>New Horizon Care</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/services">Services</Link>
            <Link href="/locations">Locations</Link>
            <Link href="/about">About Us</Link>
            <Link href="/jobs">Jobs</Link>
            <Link href="contact">Contact Us</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright {new Date().getFullYear()}, New Horizon Care. All
            Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
