import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import {Helmet} from "react-helmet";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import CareHome from "components/features/CareHome.js";

import Hanover from "images/hanover.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
          <title>Hanover | Supported Housing for Vulnerable Adults</title>
      </Helmet>        
      <Header />
      <CareHome
        subheading={<Subheading>About Hanover Supported Living</Subheading>}
        heading="Hanover Supported Living"
        address="55 Hanover Circle, Hayes, Greater London UB3 2TL"
        description="New Horizon Care Home are delighted to introduce the Hanover Supported Living, their new services to cater for clients in need of supported living. We encourage all to engage in meaningful activity, ensuring a balance of self-care, leisure and productivity (employment/education)."
        imageSrc={Hanover}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
