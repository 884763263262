import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { Helmet } from "react-helmet";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import ContactUsForm from "forms/TwoColLocationWithIllustration.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

import ArgyleImgSrc from "images/argyle-nhc.jpg";
import CentralImgSrc from "images/central-nhc.jpg";
import ChatsworthImgSrc from "images/chatsworth-nhc.jpg";
import ManorImgSrc from "images/manor-nhc.jpeg";
import HanoverImgSrc from "images/hanover-nhc.jpg";
import OldParkMewsImgSrc from "images/oldmews-nhc.jpg";
import HesaImgSrc from "images/hesa-nhc.jpg";

import {
  faLocationDot,
  faPhoneVolume,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;

const Email = tw.a`text-sm mt-6 block text-gray-600`;
const Phone = tw.a`text-sm mt-0 block text-gray-600`;
const Google = tw.a`text-sm text-pink-700`;

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Locations | Supported Housing for Vulnerable Adults</title>
      </Helmet>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Hanover (Head Office)",
            img: HanoverImgSrc,
            rooms: 5,
            beds: 5,
            gender: "Male Only",
            facilities: "Shared, Kitchen, Garden",
            link: "/hanover",
            description: (
              <>
                <Address>
                  <AddressLine>55 Hanover Circle, Hayes</AddressLine>
                  <AddressLine>
                    Greater London,{" "}
                    <Google
                      href="https://goo.gl/maps/tifSZZgNmnudakeBA"
                      target="_blank"
                    >
                      UB3 2TL <FontAwesomeIcon icon={faLocationDot} size="sm" />
                    </Google>
                  </AddressLine>
                </Address>
                <Email href="mailto:hanover@newhorizoncare.co.uk">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  hanover@newhorizoncare.co.uk
                </Email>
                <Phone href="tel:02036020450">
                  <FontAwesomeIcon icon={faPhoneVolume} /> 0203 602 0450
                </Phone>{" "}
              </>
            ),
          },
          {
            title: "Argyle",
            img: ArgyleImgSrc,
            rooms: 6,
            beds: 6,
            gender: "Male Only",
            facilities: "Shared",
            description: (
              <>
                <Address>
                  <AddressLine>98 Argyle Avenue, Witton</AddressLine>
                  <AddressLine>
                    London,{" "}
                    <Google
                      href="https://goo.gl/maps/Y1aD7TrksoiNSubc9"
                      target="_blank"
                    >
                      TW3 2LW <FontAwesomeIcon icon={faLocationDot} size="sm" />
                    </Google>
                  </AddressLine>
                </Address>
                <Email href="mailto:argyle@newhorizoncare.co.uk">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  argyle@newhorizoncare.co.uk
                </Email>
                <Phone href="tel:02088941734">
                  <FontAwesomeIcon icon={faPhoneVolume} /> 0208 894 1734
                </Phone>
              </>
            ),
          },
          {
            title: "Central",
            img: CentralImgSrc,
            rooms: 5,
            beds: 5,
            gender: "Female Only",
            facilities: "Shared, Ensuite, TV, Kitchen, Lounge",
            description: (
              <>
                <Address>
                  <AddressLine>88 Central Avenue, Hayes</AddressLine>
                  <AddressLine>
                    Greater London,{" "}
                    <Google
                      href="https://goo.gl/maps/cKSfiRHuRvG3MzMB7"
                      target="_blank"
                    >
                      UB3 2BZ <FontAwesomeIcon icon={faLocationDot} size="sm" />
                    </Google>
                  </AddressLine>
                </Address>
                <Email href="mailto:central@newhorizoncare.co.uk">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  central@newhorizoncare.co.uk
                </Email>
                <Phone href="tel:02038428206">
                  <FontAwesomeIcon icon={faPhoneVolume} /> 0203 842 8206
                </Phone>{" "}
              </>
            ),
          },
          {
            title: "Chatsworth",
            img: ChatsworthImgSrc,
            rooms: 8,
            beds: 8,
            gender: "Male and Female",
            facilities: "Shared",
            description: (
              <>
                <Address>
                  <AddressLine>56 Chatsworth Road, Hayes</AddressLine>
                  <AddressLine>
                    Greater London,{" "}
                    <Google
                      href="https://goo.gl/maps/e6QBiRZs14TCbuXBA"
                      target="_blank"
                    >
                      UB4 9EU <FontAwesomeIcon icon={faLocationDot} size="sm" />
                    </Google>
                  </AddressLine>
                </Address>
                <Email href="mailto:chatsworth@newhorizoncare.co.uk">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  chatsworth@newhorizoncare.co.uk
                </Email>
                <Phone href="tel:02035924449">
                  <FontAwesomeIcon icon={faPhoneVolume} /> 0203 592 4449
                </Phone>{" "}
              </>
            ),
          },
          {
            title: "Hesa",
            img: HesaImgSrc,
            rooms: 4,
            beds: 4,
            gender: "Male Only",
            facilities: "Shared, TV, Kitchen, Lounge, Garden",
            description: (
              <>
                <Address>
                  <AddressLine>35 Hesa Road, Hayes, Middlesex</AddressLine>
                  <AddressLine>
                    Middlesex,{" "}
                    <Google
                      href="https://goo.gl/maps/8omVL1NUpC7PoZy38"
                      target="_blank"
                    >
                      UB3 2DP <FontAwesomeIcon icon={faLocationDot} size="sm" />
                    </Google>
                  </AddressLine>
                </Address>
                <Email href="mailto:hesa@newhorizoncare.co.uk">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  hesa@newhorizoncare.co.uk
                </Email>
                <Phone href="tel:02085825595">
                  <FontAwesomeIcon icon={faPhoneVolume} /> 0208 582 5595
                </Phone>{" "}
              </>
            ),
          },
          {
            title: "Manor",
            img: ManorImgSrc,
            rooms: 6,
            beds: 6,
            gender: "Female Only",
            facilities: "Shared, Ensuite, TV, Kitchen, Lounge",
            description: (
              <>
                <Address>
                  <AddressLine>22 Manor Avenue, Hounslow</AddressLine>
                  <AddressLine>
                    Greater London,{" "}
                    <Google
                      href="https://goo.gl/maps/GkUoiio1e9M7hjZU6"
                      target="_blank"
                    >
                      TW4 7JR <FontAwesomeIcon icon={faLocationDot} size="sm" />
                    </Google>
                  </AddressLine>
                </Address>
                <Email href="mailto:manor@newhorizoncare.co.uk">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  manor@newhorizoncare.co.uk
                </Email>
                <Phone href="tel:02085812303">
                  <FontAwesomeIcon icon={faPhoneVolume} /> 0208 581 2303
                </Phone>{" "}
              </>
            ),
          },
          {
            title: "Old Park Mews",
            img: OldParkMewsImgSrc,
            rooms: 6,
            beds: 6,
            gender: "Female Only",
            facilities: "Shared",
            description: (
              <>
                <Address>
                  <AddressLine>5 Old park Mews</AddressLine>
                  <AddressLine>
                    Hounslow,{" "}
                    <Google
                      href="https://goo.gl/maps/mdbRvcwgTsnQy224A"
                      target="_blank"
                    >
                      TW5 0QD <FontAwesomeIcon icon={faLocationDot} size="sm" />
                    </Google>
                  </AddressLine>
                </Address>
                <Email href="mailto:oldmews@newhorizoncare.co.uk">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  oldmews@newhorizoncare.co.uk
                </Email>
                <Phone href="tel:02087979363">
                  <FontAwesomeIcon icon={faPhoneVolume} /> 0208 797 9363
                </Phone>{" "}
              </>
            ),
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
