import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

import ServiceOne from "images/services-1.jpeg";
import ServiceTwo from "images/services-2.jpeg";
import ServiceThree from "images/services-3.jpeg";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full max-w-5xl text-center`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-80 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${(props) =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-0 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;

const PlanImage = tw.img`rounded-t-md`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-4 py-4 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 leading-7 text-left first:mt-0 font-medium text-gray-700`}
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  subheading = "Services",
  heading = "Our Supported Living Services",
  description1 = "Our supported living services provide innovative and person-centred support enabling people with autism, learning disabilities, mental health illnesses and challenging behaviours to live as independently as possible within their own homes and in the local community.",
  description2 = "The support provided is person-centred and specifically tailored to meet every unique need, underpinned by our philosophy that every individual has the right to live the life they choose for themselves.",
  plans = null,
}) => {
  const defaultPlans = [
    {
      imgSrc: ServiceOne,
      features: [
        "Our supported Living services provides  care and support for adults with complex needs including dual diagnoses of learning disability, Epilepsy , Autistic spectrum (including Asperger’s Syndrome) and / or mental illness is available. The houses which are part of the local communities provide a safe and supportive environment, which allows everyone the opportunity to develop skills for more independent living in the future. We encourage all to engage in meaningful activity, ensuring a balance of self-care, leisure and productivity (employment/education).",
      ],
      featured: true,
    },
    {
      imgSrc: ServiceTwo,
      features: [
        "Our service is part of the mental health care pathway; it is a modern, non-clinical environment that supports people moving from secure environments into the community. We also support people in transition from secure forensic services to community living. We can support people informally, on community treatment orders and conditional discharge from the Mental Health Act before the move into the community. At New Horizon Care our goal is to promote our clients Recovery through the process of providing Hope, stability and opportunity.",
      ],
      featured: true,
    },
    {
      imgSrc: ServiceThree,
      features: [
        "The service provides person-centred care by helping people create their own care plans, identifying personal needs, the outcomes that they want in their life, and how our staff will support them in achieving this on their recovery journey. The service promotes the social inclusion of our service users, through challenging stigma and discrimination; encouraging re-integration and participation in the community, and focusing on education and employment. It also promotes positive risk taking, to increase a service user’s confidence and move towards their goals in small achievable milestones.",
      ],
      featured: true,
    },
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description1 && <Description>{description1}</Description>}
          {description2 && <Description>{description2}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <PlanImage src={plan.imgSrc} />
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
