import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import SimpleContactUs from "forms/SimpleContactUs";

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Jobs | Supported Housing for Vulnerable Adults</title>
      </Helmet>
      <Header />
      <Hero />
      <SimpleContactUs />
      <Footer />
    </AnimationRevealPage>
  );
};
