import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

// get our fontawesome imports
import {
  faBed,
  faBuildingUser,
  faRestroom,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto pb-20 md:pb-20`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm mb-8`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-3 pr-4 py-2 border-2 border-dashed border-primary-100 rounded-lg`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }

  .aminitiesContainer {
    ${tw`mt-4 mb-1`}
  }

  .aminities {
    ${tw`mt-2 text-sm font-medium text-gray-600 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Image = tw.img`rounded-md mb-4 h-auto`;

export default ({
  cards = null,
  heading = "Our Care Homes",
  subheading = "Locations",
  description = "Our services are specifically designed to provide the right community-based settings for adults over the age of 18 with complex needs.",
}) => {
  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                {card.link ? (
                  <a href={card.link}>
                    <Image
                      tw="border-2 border-primary-100"
                      src={card.img}
                      alt={card.title}
                    />
                  </a>
                ) : (
                  <Image src={card.img} alt={card.title} />
                )}
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                <div className="aminitiesContainer">
                  <p className="aminities">
                    <FontAwesomeIcon icon={faBuildingUser} /> No of Rooms:{" "}
                    {card.rooms}
                  </p>
                  <p className="aminities">
                    <FontAwesomeIcon icon={faBed} /> No of Beds: {card.beds}
                  </p>
                  <p className="aminities">
                    <FontAwesomeIcon icon={faRestroom} /> Gender: {card.gender}
                  </p>
                  <p className="aminities">
                    <FontAwesomeIcon icon={faShower} /> Facilities:{" "}
                    {card.facilities}
                  </p>
                </div>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
