import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import logo from "../../images/logo.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  // flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const NavSpan = tw.span`pb-1 border-b-2 border-primary-500 text-primary-500`;
export const NavSpanJ = tw.span`mr-8`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-5 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-2/4 mr-3`}
  }
`;

export const MobileLogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`xl:hidden w-2/4 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  xl:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`xl:hidden z-10 fixed top-0 inset-x-0 mx-2 my-6 p-8 border text-center rounded-sm text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden xl:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "xl",
}) => {
  let { path } = useRouteMatch();

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      {path === "/services" ? (
        <NavLink href="/services">
          <NavSpan>Services</NavSpan>
        </NavLink>
      ) : (
        <NavLink href="/services">Services</NavLink>
      )}
      {path === "/locations" ? (
        <NavLink href="/locations">
          <NavSpan>Locations</NavSpan>
        </NavLink>
      ) : (
        <NavLink href="/locations">Locations</NavLink>
      )}
      {path === "/gallery" ? (
        <NavLink href="/gallery">
          <NavSpan>Gallery</NavSpan>
        </NavLink>
      ) : (
        <NavLink href="/gallery">Gallery</NavLink>
      )}
      {path === "/about" ? (
        <NavLink href="/about">
          <NavSpan>About Us</NavSpan>
        </NavLink>
      ) : (
        <NavLink href="/about">About Us</NavLink>
      )}
      <NavSpanJ>
        {path === "/jobs" ? (
          <NavLink href="/jobs">
            <NavSpan>Jobs</NavSpan>
          </NavLink>
        ) : (
          <NavLink href="/jobs">Jobs</NavLink>
        )}
      </NavSpanJ>
      <PrimaryLink
        css={roundedHeaderButton && tw`rounded-full`}
        href="/contact"
      >
        Contact Us
      </PrimaryLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
    </LogoLink>
  );

  const mobileLogoLink = (
    <MobileLogoLink href="/">
      <img src={logo} alt="logo" />
    </MobileLogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {mobileLogoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            path === "/" ? (
              <CloseIcon tw="mr-4 w-6 h-6" />
            ) : (
              <CloseIcon tw="w-6 h-6" />
            )
          ) : path === "/" ? (
            <MenuIcon tw="mr-4 w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`xl:hidden`,
    desktopNavLinks: tw`xl:flex`,
    mobileNavLinksContainer: tw`xl:hidden`,
  },
};
