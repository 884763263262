import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-12 mt-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-2/5 lg:w-2/5 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-3/5 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-0`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;

export default ({ textOnLeft = true }) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <iframe
            title="CQC"
            width="80%"
            height="380px"
            src="https://maniva.co.uk/cqc.html"
          ></iframe>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>
              Support to adults with learning disabilities and mental health
              needs
            </Heading>
            <Description>
              The care service has been developed and designed in line with the
              values that underpin the Registering the Right Support and other
              best practice guidance. These values include choice, promotion of
              independence and inclusion. People with learning disabilities and
              mental health needs using the service can live as ordinary a life
              as any citizen.
            </Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
