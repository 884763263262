import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// pages
import Home from "pages/Home";
import Services from "pages/Services";
import Locations from "pages/Locations";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import Hanover from "pages/Hanover";
import Jobs from "pages/Jobs";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/locations">
          <Locations />
        </Route>
        
        <Route path="/about">
          <AboutUs />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
        <Route path="/jobs">
          <Jobs />
        </Route>
        <Route path="/hanover">
          <Hanover />
        </Route>
      </Switch>
    </Router>
  );
}
