import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";

import SupportServicesImgSrc from "images/support-services.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded-lg bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:pb-8`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;

const Statistics = tw.div`mt-4 flex flex-col`;
const Statistic = tw.div`text-xl mt-4 text-center md:text-left`;
const Key = tw.li`font-medium text-gray-700`;

export default ({ textOnLeft = false }) => {
  const statistics = [
    {
      key: "Autism Care and Support ",
      value: "192",
    },
    {
      key: "Learning Disability Support",
      value: "479",
    },
    {
      key: "Epilepsy",
      value: "2093",
    },
    {
      key: "Mental Health Support",
      value: "10347",
    },
    {
      key: "Positive Behavioural Support",
      value: "10347",
    },
    {
      key: "Supported Living Services",
      value: "10347",
    },
  ];

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={SupportServicesImgSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Our Specialist Support Services</Heading>
            <Description>
              We provide a range of person-centred support programmes in
              nurturing environments for adults aged 18 and over. These include:
            </Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
