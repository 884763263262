import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Services from "components/services/TwoPlansWithDurationSwitcher.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FAQ from "components/faqs/SingleCol.js";
import FAQ1 from "components/faqs/SingleCol1.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Services | Supported Housing for Vulnerable Adults</title>
      </Helmet>
      <Header />
      <Services />
      <FAQ />
      <FAQ1 />
      <Footer />
    </AnimationRevealPage>
  );
};
