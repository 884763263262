import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import {Helmet} from "react-helmet";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.png";
import ShieldIconImage from "images/shield-icon.png";
import CustomerLoveIconImage from "images/simple-icon.png";

import AboutUsInnovative from "images/about-us-innovative.jpeg";
import AboutUsSpecialist from "images/about-us-specialist.jpeg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
          <title>About Us | Supported Housing for Vulnerable Adults</title>
      </Helmet>      
      <Header />
      <MainFeature1
        subheading={<Subheading>About New Horizon Care</Subheading>}
        heading="Innovative and inspiring environments."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc={AboutUsInnovative}
      />
      <MainFeature1
        subheading={<Subheading>Philosophy of Care</Subheading>}
        heading="Our specialist support services."
        description="We value each person as an individual, and as part of a team, deliver the highest level of service user care which respects individuality, privacy, dignity in a safe homely setting.
        We strive to enable people to shape their own futures according to their abilities, interests and aptitudes in order to maximise their independence, to accept personal responsibility, and to respect others, whatever their level of ability might be."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc={AboutUsSpecialist}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="We follow these."
        description="Our standards of excellence are founded on the fundamental principles of good care practice. At New Horizon we aim to provide a safe and therapeutic environment for our clients where our approach will promote well being and autonomy through their recovery journey."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Social Services",
            description: "NEW HORIZON CARE works in partnership with Social Services, Community Mental Health Teams (CMHT) and Supporting People within leading Local Authorities across London offering high quality supported housing for vulnerable adults."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Our aim is to enable the people we support to achieve their goals, believing passionately that regardless of the challenges they face, everyone can accomplish extraordinary achievements with the right care in the right environment."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Support Packages",
            description: "All of the support packages we offer are tailored to the individuals needs to help them develop vocational, social, education and life skills so they can progress to a more independent future. We also create pathways through residential settings."
          },
        ]}
        linkText=""
      />
      <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
