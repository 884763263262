import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import Services from "components/features/TwoColSingleFeatureWithStats.js";
import CQCWidget from "components/features/CQCWidget";
import ContactUsForm from "forms/CallForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import ResidentLargeOne from "images/resident-large-1.jpg";
import ResidentLargeTwo from "images/resident-large-2.jpg";
import ResidentSmallOne from "images/resident-small-1.jpg";
import ResidentSmallTwo from "images/resident-small-2.jpg";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <CQCWidget />
    <MainFeature />
    <Features />
    <Portfolio />
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Residents <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Here are what some of our amazing residents are saying about our Supported Living Services."
      testimonials={[
        {
          imageSrc: ResidentLargeOne,
          profileImageSrc: ResidentSmallOne,
          quote:
            "The quality of the rooms are also very good and any requests to personalise have been dealt with promptly.",
          customerName: "Aliyah",
          customerTitle: "",
        },
        {
          imageSrc: ResidentLargeTwo,
          profileImageSrc: ResidentSmallTwo,
          quote:
            "Medical care has been excellent and the staff have been very proactive in keeping me updated after Doctors' visits to the Home.",
          customerName: "Davies",
          customerTitle: "",
        },
      ]}
      textOnLeft={true}
    />
    <Services />
    <ContactUsForm />

    <Footer />
  </AnimationRevealPage>
);
