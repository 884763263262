// config
export const config = {
  dev: {
    name: "Geektive",
    company: "Geektive Limited",
    website: "https://www.geektive.com/",
    version: "1.0.0",
  },
  api: {
    baseURL: "https://api.newhorizoncare.co.uk",
  },
  client: {
    name: "New Horizon Care Home",
    telephone: "02036020450",
    whatsApp: "02036020450",
    email: "info@newhorizoncare.co.uk",
    address1: "55 Hanover Circle",
    address2: "Hayes",
    city: "London",
    postalCode: "UB3 2TL",
    country: "United Kingdom",
    website: "https://www.newhorizoncare.co.uk/",
    domain: "www.newhorizoncare.co.uk",
    openingHours: "Monday - Sunday: 24/7",
    siteParking: "Free restricted parking is available on site.",
    support:
      "Our email support staff is available around the clock to support you with any issues.",
    latitude: "51.49748",
    longitude: "-0.45591",
  },
};
