import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import Modal from "react-modal";
import { SnackbarProvider } from "notistack";

Modal.setAppElement("#root");

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
  >
    <App />
  </SnackbarProvider>,
  document.getElementById("root")
);
